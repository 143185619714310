import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'
import FAQ from '../components/faq/faq'

const FAQPage = () => (
    <Layout>
        <SEO title="faq:title" />
        <FAQ />
    </Layout>
)

export default FAQPage
